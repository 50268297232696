import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Button } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import parse from 'html-react-parser';
import CountUp from 'react-countup';
import GenerateLink from "../../common/site/generate-link"

import "./CountBlock.scss";
import { inViewOptions, containerVariants, contentItem, fadeInFromTop } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";


const COUNT_UP_START = 0;
const COUNT_UP_DURATION = 3;

function addZero(i) {
  if (i < 10) { i = "0" + i }
  return i;
}

const CountBlock = (props) => {


  var currentdate = new Date();
  var datetime = currentdate.getDate() + "/"
    + (currentdate.getMonth() + 1) + "/"
    + currentdate.getFullYear() + " @ "
    + addZero(currentdate.getHours()) + ":"
    + addZero(currentdate.getMinutes()) + ":"
    + addZero(currentdate.getSeconds());
  return (
    <InView  {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="count-section left-content components--Home--CountBlock--CountBlock"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}

        >
          <Container>
            <Row>
              <Col lg={3} className="col-one">
                <motion.div variants={contentItem}>
                  {props.Statistics_Left_Title &&
                    <span class="sub-title">{props.Statistics_Left_Title}</span>
                  }
                  {parse(props.Statistics_Left_Content)}
                  {props.Statistics_Left_CTA_Label &&
                    <GenerateLink link={props.Statistics_Left_CTA_Link} class="btn">{props.Statistics_Left_CTA_Label}<i className="icon-arrow"></i></GenerateLink>
                  }
                </motion.div>
              </Col>
              <Col md={9} lg={8} className="ms-auto">
                <Row>
                  <Col md={12} className="ms-auto">
                    <div className="block-heading">
                      {props.Statistics_Title &&
                        <h2>{parse(props.Statistics_Title)}</h2>
                      }
                    </div>
                  </Col>
                  <Col lg={12} className="ms-auto">
                    <div className="count-row count-row-first">
                      {props.Add_Stats && props.Add_Stats.slice(0, 2).map((stats, i) => {
                        var countnum = stats.Count.replace(/[^\w\s]/gi, '')
                        var prefix = ''
                        if (stats.Count.indexOf('£') !== -1) {
                          prefix = '£'
                        }
                        return (
                          <motion.div variants={contentItem} className="count-block one">
                            <strong><CountUp
                              start={inView ? COUNT_UP_START : countnum}
                              end={countnum}
                              delay={0}
                              duration={COUNT_UP_DURATION}
                              prefix={prefix ? "£" : ''}
                              separator=","
                            /></strong>
                            {stats.Details && <span>{stats.Details}</span>}
                          </motion.div>
                        )
                      })}
                    </div>
                    {props.Add_Stats?.length > 3 &&
                      <div className="count-row count-row-last">
                        {props.Add_Stats && props.Add_Stats.slice(2, 10).map((stats, i) => {
                          var countnum = stats.Count.replace(/[^\w\s]/gi, '').replace(/\D/g,'');
                          var prefix = ''
                          var suffix = ''
                          if (stats.Count.indexOf('£') !== -1) {
                            prefix = '£'
                          }
                          if (stats.Count.charAt(stats.Count.length - 1) == "m") {
                            suffix = 'm'
                          }
                          return (
                            <motion.div key={stats + 2} custom={stats + 3} variants={contentItem} className="count-block one">
                              <strong>
                                <CountUp
                                  start={inView ? COUNT_UP_START : countnum}
                                  end={countnum}
                                  delay={0}
                                  duration={COUNT_UP_DURATION}
                                  prefix={prefix ? "£" : ''}
                                  suffix={suffix ? "m" : ''}
                                  separator=","
                                />
                              </strong>
                              {stats.Details && <span>{stats.Details}</span>}
                            </motion.div>
                          )
                        })}
                      </div>
                    }
                    {props.About_Statistics ?
                      <p><b>Source:</b> Wards, part of Arun Estates Ltd, correct as of {datetime}</p> : ''}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </motion.div>
      )}
    </InView>
  );
};

export default CountBlock;
